<template>
    <el-dialog :title="title + '分享推荐列表'" :visible.sync="dialogVisible" width="85%" :close-on-click-modal="false" top="6vh"
        :before-close="handleClose">
        <div style="height:500px">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
                height="460">
                <el-table-column label="用户id" align="center" prop="userId" width="100">
                </el-table-column>
                <el-table-column label="用户昵称" align="center" prop="nickName" min-width="300">
                </el-table-column>
                <el-table-column label="用户头像" align="center" width="100">
                    <template slot-scope="scope">
                        <el-avatar size="small" :src="scope.row.avatarUrl"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" align="center" prop="phone" width="130">
                </el-table-column>
                <el-table-column label="真实姓名" align="center" prop="fullName" width="130">
                </el-table-column>
                <el-table-column prop="status" align="center" label="用户状态" width="120">
                    <template slot-scope="scope">
                        <el-tag size="small" v-if="scope.row.status === 0" type="info">封号</el-tag>
                        <el-tag size="small" v-else-if="scope.row.status === 1" type="success">有效</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="identity" align="center" label="身份" width="120">
                    <template slot-scope="scope">
                        <el-tag size="small" v-if="scope.row.identity === 2" type="info">货主</el-tag>
                        <el-tag size="small" v-else-if="scope.row.identity === 3" type="success">司机</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="isAuthentication" align="center" label="是否认证" width="120">
                    <template slot-scope="scope">
                        <el-tag size="small" v-if="scope.row.isAuthentication === 0" type="info">未认证</el-tag>
                        <el-tag size="small" v-else-if="scope.row.isAuthentication === 1" type="success">已认证</el-tag>
                        <el-tag size="small" v-else-if="scope.row.isAuthentication === 2" type="success">认证中</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" align="center" width="200" label="创建时间">
                </el-table-column>

            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="data.pageNo"
                :page-size="data.pageSize" :total="total">
            </el-pagination>
        </div>
    </el-dialog>
</template>

<script>
import VploadImgTwo from "../inc/VploadImgTwo";
export default {
    name: "AddUpdateVehicletype",
    components: {
        VploadImgTwo
    },
    data() {
        return {
            title: '',
            dialogVisible: false,
            total: 0,
            tableData: [],
            data: {
                pageNo: 1,
                pageSize: 10,
                userId: 0
            }
        }
    },
    methods: {
        handleSizeChange(val) {
            console.log(val)
            this.data.pageSize = val
            this.getUserBase()
        },
        handleCurrentChange(val) {
            this.data.pageNo = val
            this.getUserBase()
        },
        handleClose() {
            this.dialogVisible = false;
        },
        getUserBase() {
            this.$axios.post("/admin/userBase/getShareUserList", this.data).then(res => {
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
        init(e) {
            this.dialogVisible = true;
            this.data.pageNo = 1
            this.data.pageSize = 10
            this.data.userId = e.userId
            this.title = e.nickName
            this.getUserBase()
        }
    }
}
</script>

<style scoped></style>
